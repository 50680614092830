import { useEffect, useState } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import confluenceLogo from '../img/Confluence-blue.svg';
import jiraLogo from '../img/Jira Software-blue.svg';
import placeholderLogo from '../img/placeholder.svg';
import './App.css';
import { Provider } from "@fluentui/react-teams";
import { themeNames } from "@fluentui/react-teams";
import sanitizeHtml from 'sanitize-html';
import pako from 'pako';
// note: https://fluentsite.z22.web.core.windows.net/0.54.0/components/button/definition
import { Flex, Text, Segment, Avatar, Button} from "@fluentui/react-northstar";
import { AzureIntegrationActivityForFrontend } from '../AzureIntegrationActivities';
import { useTranslation, withTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { Base64 } from 'js-base64';

function App() {
  const [activitySenderName, setActivitySenderName] = useState("Activity Sender");
  const [activityContextTitle, setActivitySourceContextTitle] = useState("Source Context");
  const [activityContent, setActivityContent] = useState("Placeholder");
  const [activityUrl, setActivityUrl] = useState("#");
  const [activitySource, setActivitySource] = useState<"UNDEFINED" | "CONFLUENCE" | "JIRA">("UNDEFINED");
  const [, setAppContext] = useState<microsoftTeams.Context>();
  const [appAppearance, ] = useState<themeNames>(
    themeNames.Default
  );
  const { t, } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    /**
     * With the context properties in hand, your app has a solid understanding of what's happening around it in Teams.
     * https://docs.microsoft.com/en-us/javascript/api/@microsoft/teams-js/context?view=msteams-client-js-latest&preserve-view=true
     **/
    microsoftTeams.getContext((context) => {
      console.log("Successfully got context in App.tsx");
      console.log(`context.subEntityId: ${context.subEntityId}`);
      setAppContext(context);
      if (typeof context.subEntityId !== "undefined" && context.subEntityId) {
        const base64 = context.subEntityId;//.replace("-", "=").replace("-", "=");
        const json = pako.inflate(Base64.toUint8Array(base64), {to: "string"});

        console.log(`decoded JSON string: ${json}`);
        const data: AzureIntegrationActivityForFrontend = JSON.parse(json ?? "{}");
        setActivitySenderName(data.senFullName || activitySenderName);
        setActivitySourceContextTitle(data.acCtxTitle || activityContextTitle);
        setActivityContent(data.acContent);
        setActivityUrl(data.acUrl || activityUrl);
        setActivitySource(data.acSource);
      } else {
        history.push("/about");
      }
    });
  },);

  return (
    <Provider themeName={appAppearance} lang="en-US">
      <div className="App">
        {/* <header className="App-header"> */}
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        <div>
          <Flex gap="gap.large" column>
            <Segment className="segment">
              <Flex gap="gap.medium">
                <Avatar name={activitySenderName} />
                <Flex column gap="gap.smaller">
                  <Text size="large">{activitySenderName}&nbsp;{t("notifications.mentioned_you_on")}:</Text>
                  <Text size="large" weight="semibold">{activityContextTitle}</Text>
                </Flex>
              </Flex>
            </Segment>
            <Segment className="segment">
              <Flex column gap="gap.medium">
                <Text dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(activityContent, {allowedClasses: { '*': ['user-mention', 'current-user-mention', 'confluence-userlink', 'data-*']}})
                  }}></Text>
                <Button content={t("notifications.jump_to_mention")} primary onClick={() => { window.open(activityUrl, "_blank"); }} />
              </Flex>
            </Segment>
            <img src={activitySource === "JIRA" ? jiraLogo : activitySource === "CONFLUENCE" ? confluenceLogo : placeholderLogo} className="source-logo" alt="logo" />
          </Flex>
        </div>
      </div>
    </Provider>
  );
}

export default withTranslation()(App);
