import { Flex, Text} from "@fluentui/react-northstar";
import { withTranslation, useTranslation } from "react-i18next";

function About() {
  const { t } = useTranslation();
  return (
      <Flex gap="gap.large" column>
        <h2>{t("app.title")}</h2>
        <Text>{t("app.about")}</Text>
      </Flex>
    );
  }

  export default withTranslation()(About);