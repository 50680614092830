import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from './locale/en.json';
import de from './locale/de.json';

console.debug("TEST");

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        lng: window.navigator.language,
        fallbackLng: 'de',
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        resources: {en, de},
        debug: true
    });

export default i18n;