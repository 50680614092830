import { useEffect, useState } from "react";
import * as microsoftTeams from "@microsoft/teams-js";
import { Provider } from "@fluentui/react-teams";
import { themeNames } from "@fluentui/react-teams";
import About from "./components/About";
import App from "./components/App";
import './AppContainer.css';

import { Route, BrowserRouter as Switch } from 'react-router-dom';
import { useHistory } from "react-router-dom";

import { useTranslation } from 'react-i18next';


export default function AppContainer() {
  console.log("Start: AppContainer");
  const { i18n } = useTranslation();
  const [appAppearance, setAppAppearance] = useState<themeNames>(
    themeNames.Default
  );
  let teamsInitialized = false;
  const history = useHistory();
  microsoftTeams.initialize(() => teamsInitialized = true);
  setTimeout(() => {
    if(teamsInitialized) {
     // teams running
    } else {
      history.push("/about");
    }
  }, 500);

  microsoftTeams.getContext((context) => {
    console.log(`Successfully got context in AppContainer.tsx, checking if current language setting ${i18n.language} is equal to the context.locale ${context.locale}`);
    if(i18n.language !== context.locale) {
      console.log("Changing language");
      i18n.changeLanguage(context.locale);
    }
  });

  useEffect(() => {
    /**
     * With the context properties in hand, your app has a solid understanding of what's happening around it in Teams.
     * https://docs.microsoft.com/en-us/javascript/api/@microsoft/teams-js/context?view=msteams-client-js-latest&preserve-view=true
     **/
    microsoftTeams.getContext((context) => {
      console.log("Successfully got context in AppContainer.tsx");
      setAppAppearance(initTeamsTheme(context.theme));
      microsoftTeams.appInitialization.notifySuccess();
    });

    /**
     * Theme change handler
     * https://docs.microsoft.com/en-us/javascript/api/@microsoft/teams-js/microsoftteams?view=msteams-client-js-latest#registerOnThemeChangeHandler__theme__string_____void_
     **/
    microsoftTeams.registerOnThemeChangeHandler((theme) => {
      console.log("Theme change handler triggered: calling setAppAppearance");
      setAppAppearance(initTeamsTheme(theme));
    });
  }, []);

  console.log("Returning from AppContainer");
  return (
    <Provider themeName={appAppearance} lang="en-US">
      <div className="content">
          <Switch>
            <Route exact path="/">
              <App />
            </Route>
            <Route path="/about">
              <About />
            </Route>
            <Route path="/privacy">
              <About />
            </Route>
          </Switch>
      </div>
    </Provider>
  );
}

// Possible values for theme: 'default', 'light', 'dark' and 'contrast'
function initTeamsTheme(theme: string | undefined): themeNames {
  switch (theme) {
    case "dark":
      return themeNames.Dark;
    case "contrast":
      return themeNames.HighContrast;
    default:
      return themeNames.Default;
  }
}
